import "./Partners.scss";
import "aos/dist/aos.css";

// Main partner logo
import seatrade from "../../Resources/Pics/partners/seatrade-main-white.png";
// Main partners
import rug from "../../Resources/Pics/partners/rug-large.svg";
import hanze from "../../Resources/Pics/partners/new-hanze-logo.svg";
import noord from "../../Resources/Pics/partners/noord-large.svg";
import alfa from "../../Resources/Pics/partners/alfa-large.svg";
import ROC from "../../Resources/Pics/partners/ROC-large.svg";
import firda from "../../Resources/Pics/partners/firda-knowledge.svg";
// green partners
import provGron from "../../Resources/Pics/partners/provincie-groningen-green.svg";
import vanGroningen from "../../Resources/Pics/partners/vangroningen-green.svg";
import oxfordPV from "../../Resources/Pics/partners/oxford-green.svg";
// platinum partners
import viro from "../../Resources/Pics/partners/viro-green.svg";
import loxam from "../../Resources/Pics/partners/loxam-plat.svg";
import greatwaves from "../../Resources/Pics/partners/greatwaves-plat.svg";
// import ansys from "../../Resources/Pics/partners/ansys-plat.svg";
import oldernburg from "../../Resources/Pics/partners/oldernburg-plat.svg";
// gold partners
import indi from "../../Resources/Pics/partners/indi-gold.svg";
import nedcam from "../../Resources/Pics/partners/nedcam-gold.svg";
import RDW from "../../Resources/Pics/partners/RDW-gold.svg";
import vanmossel from "../../Resources/Pics/partners/vanmossel-gold.svg";
import fokker from "../../Resources/Pics/partners/fokker-gold.svg";
import sony from "../../Resources/Pics/partners/sony-gold.svg";
import assen from "../../Resources/Pics/partners/assen-gold.svg";
import pouw from "../../Resources/Pics/partners/pouw-gold.svg";
import rabobank from "../../Resources/Pics/partners/rabobank-gold.svg";
import weather from "../../Resources/Pics/partners/weather-gold.svg";
// silver partners
import infinite from "../../Resources/Pics/partners/infinite-silver.svg";
import salomon from "../../Resources/Pics/partners/salomon-silver.svg";
import kvaser from "../../Resources/Pics/partners/kvaser-silver.svg";
import eekels from "../../Resources/Pics/partners/eekels-silver.svg";
import simba from "../../Resources/Pics/partners/simba-silver.svg";
import miontronics from "../../Resources/Pics/partners/miontronics-silver.svg";
import eurocircuits from "../../Resources/Pics/partners/eurocircuits-silver.svg";
import k3d from "../../Resources/Pics/partners/k3d-silver.svg";
import TPEE from "../../Resources/Pics/partners/TPEE-silver.svg";
import raytek from "../../Resources/Pics/partners/raytek-silver.svg";
import rood from "../../Resources/Pics/partners/rood-silver.svg";
import mabutex from "../../Resources/Pics/partners/mabutex-silver.svg";
// bronze partners
import boikon from "../../Resources/Pics/partners/boikon-bronze.svg";
import easy_composites from "../../Resources/Pics/partners/easy-composites-bronze.svg";
import defensity from "../../Resources/Pics/partners/defensity-bronze.svg";
import dutchbeans from "../../Resources/Pics/partners/dutchbeans-bronze.svg";
import trip from "../../Resources/Pics/partners/trip-bronze.svg";
import wurth from "../../Resources/Pics/partners/wurth-bronze-new.svg";
import altium from "../../Resources/Pics/partners/altium-bronze.svg";
import emkay from "../../Resources/Pics/partners/emkay-bronze.svg";
import prohelion from "../../Resources/Pics/partners/prohelion-bronze.svg";
import d3 from "../../Resources/Pics/partners/d3-bronze.svg";
import hexagon from "../../Resources/Pics/partners/hexagon-bronze.svg";
import meilink from "../../Resources/Pics/partners/meilink-bronze.svg";
import igus from "../../Resources/Pics/partners/igus-bronze.svg";
import elcee from "../../Resources/Pics/partners/elcee-bronze.svg";
import vdl from "../../Resources/Pics/partners/vdl-bronze.svg";
import roboflex from "../../Resources/Pics/partners/roboflex-bronze.svg";
// import hydroflex from "../../Resources/Pics/partners/hydroflex-bronze.svg";
// import adruu from "../../Resources/Pics/partners/adruu-bronze.svg";
import bridgestone from "../../Resources/Pics/partners/bridgestone-bronze.svgz";
import stuwkrach from "../../Resources/Pics/partners/stuwkrach-broze.svg";
import unive from "../../Resources/Pics/partners/unive-bronze.svg";
import fluctus from "../../Resources/Pics/partners/fluctus-bronze.svg";
import makerspace from "../../Resources/Pics/partners/makerspace-bronze.svg";
import tkp from "../../Resources/Pics/partners/tkp-bronze.svg";
import repos from "../../Resources/Pics/partners/repos-bronze.svg";
import bionic from "../../Resources/Pics/partners/bionic-bronze.svg";
import saba from "../../Resources/Pics/partners/saba-bronze.svg";
import pontifex from "../../Resources/Pics/partners/pontifex-bronze.svg";
import target from "../../Resources/Pics/partners/target-bronze.svg";
import canon from "../../Resources/Pics/partners/canon-bronze.svg";
// import hydro from "../../Resources/Pics/partners/hydro-bronze.svg";
import omnyacc from "../../Resources/Pics/partners/omnyacc-bronze.svg";
import stm from "../../Resources/Pics/partners/STM.svg";
import PartnersInfo from "./Partners-info";
import cablemasters from "../../Resources/Pics/partners/cablemasters-gold.svg";
import de_boer from "../../Resources/Pics/partners/de-boer-bouwmanagement-bronze-duotone1.svg"; 
import sportconfex from "../../Resources/Pics/partners/sportconfex.svg";

// Main partner link
const seatradeLink = "https://www.seatrade.com";

function Partners() {
  return (
    <>
      <PartnersInfo />
      {/* Green partners */}
      {/* <div className="container seatrade rounded-4">
        <h1>Our Main partner</h1>
        <br></br>
        <div className="row justify-content-around"> was commented
         <div className="col-md-6"> 
          <a href={seatradeLink} target="_blank">
            <img src={seatrade} className="img-fluid" alt="seatrade"></img>
          </a>
          <div className="motto-seatrade">
            <p>ALWAYS. MOVING. FORWARD.</p>
          </div> 
        </div>
        was commented below
         <div className="col-md-6">
            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam purus arcu, faucibus eu lacus id, tristique tempor urna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla nec arcu egestas odio laoreet iaculis. Sed dapibus metus ac imperdiet rutrum. Sed sed mauris eros. In dictum porttitor condimentum. Ut at mauris sed quam vestibulum aliquam. Fusce semper purus non quam facilisis, ut tincidunt est bibendum. Etiam eleifend id tellus at luctus. Phasellus euismod condimentum justo id volutpat. </p>
          </div> 
        end commented
         </div> 
       </div> */}
      <div className="container green-partners rounded-4">
        <div className="row justify-content-around title-partners">
          <h1>Our Green partners</h1>
          <div className="col-md-6">
            <a href="https://www.rug.nl/" target="_blank" rel="noreferrer">
              <img src={rug} alt="RuG" className="img-fluid"></img>
            </a>
            <p>
              The University of Groningen is a research university in the
              Netherlands that places great emphasis on student-led initiatives
              and projects. With a range of resources and support available,
              students are encouraged to take ownership of their learning and
              pursue their passions through hands-on experiences and
              collaborative endeavours.
            </p>
          </div>
          <div className="col-md-6">
            <a href="https://www.hanze.nl/nld" target="_blank" rel="noreferrer">
              <img src={hanze} alt="Hanze" className="img-fluid"></img>
            </a>
            <p>
              The Hanze University of Applied Sciences in the Netherlands is
              known for its focus on practical education and its commitment to
              supporting student-led projects. With a strong emphasis on
              entrepreneurship and innovation, students are encouraged to take
              the lead in developing creative solutions to real-world problems
              and to gain valuable experience that will serve them well in their
              future careers.
            </p>
          </div>
          <div className="col-md-6">
            <a
              href="https://www.provinciegroningen.nl/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={provGron} alt="prov Gron" className="img-fluid"></img>
            </a>
            <p>
              The Provincial Government of Groningen in the Netherlands is
              committed to supporting sustainability and innovation through
              student-led projects. With a range of funding opportunities and
              partnerships available, students are encouraged to develop
              solutions to environmental challenges and to contribute to the
              region's transition to a more sustainable future.
            </p>
          </div>
          <div className="col-md-6">
            <a
              href="https://www.visitgroningen.nl/nl"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={vanGroningen}
                alt="van Gron"
                className="img-fluid"
              ></img>
            </a>
            <p>
              The Municipality of Groningen is committed to sustainability and
              supporting student-led projects that contribute to a more
              sustainable future. With a range of initiatives and partnerships,
              the municipality encourages students to develop innovative
              solutions to environmental challenges and to play an active role
              in building a more sustainable community for all residents.
            </p>
          </div>
          {/* <div className="col-md-4">
            <a
              href="https://www.oxfordpv.com/"
              target="_blank"
              rel="noreferrer"
              style={{ filter: "invert(1)" }}
            >
              <img src={oxfordPV} alt="Oxford PV" className="img-fluid"></img>
            </a>
            <p>
              Oxford PV is a pioneer and technology leader in the field of
              perovskite solar cells. The company was established in 2010 as a
              spin-out from the University of Oxford. They focus exclusively on
              developing and commercialising a perovskite-based solar
              technology. Their research and development site in Oxford, UK, and
              their pilot and production line near Berlin, Germany enable the
              accelerated transfer of their technology into industrial-scale
              perovskite-on-silicon tandem solar cell manufacturing.
            </p>
          </div> */}
        </div>
      </div>
      {/* Main partners */}
      <div className="partners-main">
        <div className="container main-partners rounded-4">
          <div className="row justify-content-around title-partners">
            <h1>Our Knowledge partners</h1>

            <div className="col-md-4">
              <a
                href="https://noorderpoort.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={noord} alt="Noorderpoort" className="img-fluid"></img>
              </a>
              <p>
                Noorderpoort is a vocational education institution in the
                Netherlands that encourages students to develop innovative
                solutions to environmental challenges through hands-on projects
                and initiatives. With a focus on practical skills and real-world
                experience, Noorderpoort supports student-led projects and
                places a strong emphasis on sustainability.
              </p>
            </div>
            <div className="col-md-4">
              <a
                href="https://www.alfa-college.nl/groningen"
                target="_blank"
                rel="noreferrer"
              >
                <img src={alfa} alt="Alfa-college" className="img-fluid"></img>
              </a>
              <p>
                Alfa-college is an educational institution in Groningen that
                places a strong emphasis on sustainability and encourages
                students to develop innovative solutions to environmental
                challenges through hands-on projects and initiatives. Students
                from Alfa-college have helped our drivers training by developing
                a custom made simulator, getting them ready for the rugged
                expedition in Australia.
              </p>
            </div>
            <div className="col-md-4">
              <a
                href="https://www.firda.nl/"
                target="_blank"
                rel="noreferrer"
                style={{ filter: "invert(1)" }}
              >
                <img src={firda} alt="firda" className="img-fluid"></img>
              </a>
              <p>
                Firda is a vocational education institution in the Netherlands
                that offers students opportunities to develop their skills
                through practical training and experience. The institution is
                committed to promoting sustainability and supporting student-led
                projects aimed at addressing environmental challenges. With a
                focus on vocational education and training, Firda provides
                students with the knowledge and tools to make a positive impact
                on the environment.
              </p>
            </div>
          </div>
        </div>

        {/* Platinum partners */}
        <div className="container platinum-partners rounded-4">
          <div className="row justify-content-around title-partners">
            <h1>Our Platinum partners</h1>

            {/* <div className="col-md-4">
              <a
                href="https://www.viro-group.com/nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={viro} alt="Viro" className="img-fluid"></img>
              </a>
              <p>
                VIRO Group played an invaluable role in helping us develop
                mechanical solutions for our project. Their team of experts
                provided innovative ideas and practical solutions, enabling us
                to optimize our design and achieve our goals. With VIRO Group's
                expertise, we were able to push the boundaries of what was
                possible and take our project to new heights.
              </p>
            </div> */}
<div className="col-md-6">

              <a
                href="https://www.oldenburgerfritom.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={oldernburg}
                  alt="Oldenburger"
                  className="img-fluid"
                ></img>
              </a>
              <p>
                Oldenburger Fritom played an instrumental role in our project,
                providing us with essential logistical support that made our
                goals achievable. Their expertise and services enabled us to
                transport our car to Australia, as well as other components
                across the world. With their help, we were able to focus on our
                project's innovation and performance, knowing that the logistics
                were in reliable hands. We are grateful for Oldenburger Fritom's
                crucial support and partnership.
              </p>
            </div>

            <div className="col-md-6">
              <a
                href="https://seatrade.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={seatrade}
                  alt="Seatrade"
                  className="img-fluid" 
                ></img>
              </a>
              <p>
              Seatrade has been one of our most vital sponsors in our solar racing journey,
              providing reliable transportation of our container to the other side of the world.
              Their seamless handling of the overseas logistics has ensured that our materials and
              equipment arrive in Australia safely and on time. Thanks to Seatrade's support, 
              we were able to focus entirely on our preparations and performance, without worrying 
              about the logistical challenges of the trip. We deeply appreciate their trust and commitment 
              to our mission of pushing the boundaries of sustainable transportation.
              </p>
            </div>

            <div className="col-md-6">
              <a
                href="https://www.greatwaves.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={greatwaves}
                  alt="Greatwaves"
                  className="img-fluid"
                ></img>
              </a>
              <p>
                Greatwaves was an essential partner for our solar car project.
                As a student team, we were thrilled to receive their expertise
                in telemetry technology. With their cutting-edge solutions, we
                were able to optimize our car's performance and stay connected
                on our journey. Greatwaves truly pushed the boundaries of solar
                car innovation alongside us.
              </p>
            </div>

            <div className="col-md-6">
              <a
                href="https://www.gknaerospace.com/en/about-gkn-aerospace/fokker-technologies/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fokker}
                  alt="https://www.gknaerospace.com/en/about-gkn-aerospace/fokker-technologies/"
                  className="img-fluid"
                ></img>
              </a>
              <p>
              Fokker is a renowned Dutch aerospace company with a long history in aviation. 
              They specialize in designing and manufacturing aircraft components, including wings, fuselages, and landing gear systems,
               as well as providing maintenance and support services. Thanks to their support and the use of their workspaces, we are able to produce and 
               manufacture the entire aeroshell of our solar cars.
              </p>
            </div>

          </div>
        </div>


        {/* Gold partners */}
        <div className="container gold-partners rounded-4">
          <div className="row justify-content-around title-partners">
            <h1>Our Gold partners</h1>

            <div className="col-md-4">
              <a
                href="https://www.bionicsurface.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={bionic}
                  alt="https://www.bionicsurface.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            <div className="col-md-4">
              <a href="https://www.rdw.nl/" target="_blank" rel="noreferrer">
                <img src={RDW} alt="RDW" className="img-fluid"></img>
              </a>
            </div>
            
            <div className="col-md-4">
              <a href="https://www.sony.nl/" target="_blank" rel="noreferrer">
                <img
                  src={sony}
                  alt="https://www.sony.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            <div className="col-md-4">
              <a href="https://www.assen.nl/" target="_blank" rel="noreferrer">
                <img
                  src={assen}
                  alt="https://www.assen.nl/"
                  className="img-fluid"
                  style={{ width: '190px', height: '190px' }}
                ></img>
              </a>
            </div>

            <div className="col-md-4">
              <a
                href="https://cablemasters.nl"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={cablemasters}
                  alt="https://cablemasters.nl"
                  className="img-fluid"
                  style={{ width: '180px', height: '180px' }}
                ></img>
              </a>
            </div>

            <div className="col-md-4">
              <a
                href="https://www.pouwrent.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={pouw}
                  alt="https://www.pouwrent.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            <div className="col-md-4">
              <a
                href="https://www.rabobank.nl"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={rabobank}
                  alt="https://www.rabobank.nl"
                  className="img-fluid"
                  style={{ width: '160px', height: '160px' }}
                ></img>
              </a>
            </div>

            {/* <div className="col-md-4">
              <a
                href="https://www.ibm.com/weather"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={weather}
                  alt="https://www.ibm.com/weather"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-4">
              <a
                href="https://www.tkppensioen.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={tkp}
                  alt="https://www.tkppensioen.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
          </div>
        </div>


        {/* Silver partners */}
        <div className="container silver-partners rounded-4">
          <div className="row justify-content-around title-partners">
            <h1>Our Silver partners</h1>
            <div className="col-md-3">
              <a
                href="https://www.indi.nl/nl-nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={indi} alt="Indi" className="img-fluid"></img>
              </a>
            </div>

            {/* <div className="col-md-2">
              <a
                href="https://www.dutchbeans.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={dutchbeans}
                  alt="dutchbeans"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            <div className="col-md-3"> 
              <a
                href="https://www.infinite.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={infinite} alt="Infinite" className="img-fluid" style={{ filter: 'invert(1)' }}></img>
              </a>
            </div>

            <div className="col-md-3">
              <a href="https://trip.nl/" target="_blank" rel="noreferrer">
                <img
                  src={trip}
                  alt="trip-advocated"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            {/* <div className="col-md-2">
              <a
                href="https://www.simbacarhire.com.au/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={simba} alt="simba" className="img-fluid"></img>
              </a>
            </div> */}

            {/* <div className="col-md-2">
              <a
                href="https://miontronics.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={miontronics}
                  alt="https://miontronics.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            <div className="col-md-3">
              <a
                href="https://www.eurocircuits.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={eurocircuits}
                  alt="https://www.eurocircuits.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            {/* <div className="col-md-2">
              <a href="https://k3d.nl/en/" target="_blank" rel="noreferrer">
                <img
                  src={k3d}
                  alt="https://k3d.nl/en/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            {/* <div className="col-md-2">
              <a href="https://www.tpee.nl/" target="_blank" rel="noreferrer">
                <img
                  src={TPEE}
                  alt="https://www.tpee.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            {/* <div className="col-md-2">
              <a
                href="https://www.cnrood.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={rood}
                  alt="https://www.cnrood.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            <div className="col-md-3">
              <a href="https://meilink.eu/en/" target="_blank" rel="noreferrer">
                <img
                  src={meilink}
                  alt="https://meilink.eu/en/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            {/* <div className="col-md-2">
              <a
                href="https://salomons-metalen.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={salomon}
                  alt="https://salomons-metalen.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            <div className="col-md-3">
              <a
                href="https://mabutexbedrijfskleding.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={mabutex}
                  alt="https://mabutexbedrijfskleding.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            <div className="col-md-3">
              <a href="https://omnyacc.nl/" target="_blank" rel="noreferrer">
                <img
                  src={omnyacc}
                  alt="https://omnyacc.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            <div className="col">
              <a href="https://nedcam.com/en/" target="_blank" rel="noreferrer">
                <img src={nedcam} alt="Nedcam" className="img-fluid"></img>
              </a>
            </div>

          </div>
        </div>


        {/* Bronze partners */}
        <div className="container bronze-partners rounded-4">
          <div className="row justify-content-around title-partners">
            <h1>Our Bronze partners</h1>
            <div className="col-md-3">
              <a href="https://www.loxam.nl/" target="_blank" rel="noreferrer">
                <img src={loxam} alt="Loxam" className="img-fluid invert"></img>
              </a>
            </div>
            {/* <div className="col-md-2 ">
              <a
                href="https://werkenbijdefensie.nl/interessegebieden/werkstudent"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={defensity}
                  alt="defensity-college"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            {/* <div className="col-md-2">
              <a
                href="https://www.we-online.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={wurth} alt="wurth" className="img-fluid"></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a
                href="https://www.linkedin.com/company/ray-tek/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={raytek}
                  alt="https://www.linkedin.com/company/ray-tek/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            {/* <div className="col-md-2">
              <a
                href="https://www.kvaser.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={kvaser} alt="Kvaser" className="img-fluid"></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a
                href="https://www.easycomposites.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={easy_composites}
                  alt="easy_composite"
                  className="img-fluid"
                ></img>
              </a>
            </div>
            {/* <div className="col-md-2">
              <a href="https://www.boikon.com" target="_blank" rel="noreferrer">
                <img src={boikon} alt="boikon" className="img-fluid"></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a href="https://www.altium.com" target="_blank" rel="noreferrer">
                <img src={altium} alt="altium" className="img-fluid"></img>
              </a>
            </div>
            {/* <div className="col-md-2">
              <a
                href="https://emkayplastics.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={emkay} alt="Emkay" className="img-fluid"></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a href="https://elcee.com/" target="_blank" rel="noreferrer">
                <img
                  src={elcee}
                  alt="https://elcee.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a href="https://www.igus.com/" target="_blank" rel="noreferrer">
                <img
                  src={igus}
                  alt="https://www.igus.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a
                href="https://www.linkedin.com/in/jurgen-verstoep-4368ab8/?originalSubdomain=nl"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={d3}
                  alt="https://www.linkedin.com/in/jurgen-verstoep-4368ab8/?originalSubdomain=nl"
                  className="img-fluid"
                ></img>
              </a>
            </div>
            {/* <div className="col-md-2">
              <a
                href="https://www.insummainnovation.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={hexagon}
                  alt="https://www.insummainnovation.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}

            {/* <div className="col-md-2">
              <a
                href="https://www.prohelion.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={prohelion}
                  alt="https://www.prohelion.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a
                href="https://www.vdlgroep.com/en"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={vdl}
                  alt="https://www.vdlgroep.com/en"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a
                href="https://www.roboflex.nl/nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={roboflex}
                  alt="https://www.roboflex.nl/nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a
                href="https://www.hydroflex.nl/en"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={hydroflex}
                  alt="https://www.hydroflex.nl/en"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a href="https://www.adruu.nl/" target="_blank" rel="noreferrer">
                <img
                  src={adruu}
                  alt="https://www.adruu.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a
                href="https://www.bridgestone.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={bridgestone}
                  alt="https://www.bridgestone.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div>
            {/* <div className="col-md-2">
              <a
                href="https://www.stuwkrachtinnovaties.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={stuwkrach}
                  alt="https://www.stuwkrachtinnovaties.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a href="https://www.unive.nl/" target="_blank" rel="noreferrer">
                <img
                  src={unive}
                  alt="https://www.unive.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a href="https://fluctus.nl/en/" target="_blank" rel="noreferrer">
                <img
                  src={fluctus}
                  alt="https://fluctus.nl/en/"
                  className="img-fluid"
                ></img>
              </a>
            </div>

            <div className="col-md-3">
              <a
                href="https://makerspace.hanze.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={makerspace}
                  alt="https://makerspace.hanze.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div>
            {/* <div className="col-md-2">
              <a href="https://repos.nl/" target="_blank" rel="noreferrer">
                <img
                  src={repos}
                  alt="https://repos.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a
                href="https://www.saba-adhesives.com/nl-nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={saba}
                  alt="https://www.saba-adhesives.com/nl-nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a
                href="https://pontifexcertificatie.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={pontifex}
                  alt="https://pontifexcertificatie.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            {/* <div className="col-md-2">
              <a href="https://target.nl/" target="_blank" rel="noreferrer">
                <img
                  src={target}
                  alt="https://target.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
            <div className="col-md-3">
              <a href="https://www.canon.nl/" target="_blank" rel="noreferrer">
                <img
                  src={canon}
                  alt="https://www.canon.nl/"
                  className="img-fluid"
                ></img>
              </a>
            </div>
            <div className="col-md-3">
              <a
                href="https://www.st.com/content/st_com/en.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={stm}
                  alt="https://www.st.com/content/st_com/en.html"
                  className="img-fluid"
                  
                ></img>
              </a>
            </div>
            
            <div className="col-md-3">
              <a
                href="https://www.deboerbouwmanagement.nl"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={de_boer}
                  alt="https://www.deboerbouwmanagement.nl"
                  className="img-fluid"
                  style={{ width: '150px' }}
                ></img>
              </a>
            </div>

            <div className="col-md-3">
              <a
                href="https://sportconfex.nl"
                target="_blank"
                rel="noreferrer"
                >
              <img 
                src={sportconfex}
                alt="https://sportconfex.nl"
                className="img-fluid invert"
                style  ={{ width: '130px', height: '60px', marginTop: '17px' }}
              ></img>
              </a>
            </div>
            
            {/* <div className="col-md-2">
              <a href="https://www.hydro.com/" target="_blank" rel="noreferrer">
                <img
                  src={hydro}
                  alt="https://www.hydro.com/"
                  className="img-fluid"
                ></img>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;
