import PartnerScroll from "./partnerScroll"
import "../../custom.scss"
import "./Partners.scss";

import seatrade from "../../Resources/Pics/partners/seatrade-main-white.png";
// Main partners
import rug from "../../Resources/Pics/partners/rug-large.svg";
import hanze from "../../Resources/Pics/partners/new-hanze-logo.svg";
import noord from "../../Resources/Pics/partners/noord-large.svg";
import alfa from "../../Resources/Pics/partners/alfa-large.svg";
import ROC from "../../Resources/Pics/partners/ROC-large.svg";
import firda from "../../Resources/Pics/partners/firda-knowledge.svg";
// green partners
import provGron from "../../Resources/Pics/partners/provincie-groningen-green.svg";
import vanGroningen from "../../Resources/Pics/partners/vangroningen-green.svg";
import oxfordPV from "../../Resources/Pics/partners/oxford-green.svg";
// platinum partners
import viro from "../../Resources/Pics/partners/viro-green.svg";
import loxam from "../../Resources/Pics/partners/loxam-plat.svg";
import greatwaves from "../../Resources/Pics/partners/greatwaves-plat.svg";
// import ansys from "../../Resources/Pics/partners/ansys-plat.svg";
import oldernburg from "../../Resources/Pics/partners/oldernburg-plat.svg";
// gold partners
import indi from "../../Resources/Pics/partners/indi-gold.svg";
import nedcam from "../../Resources/Pics/partners/nedcam-gold.svg";
import RDW from "../../Resources/Pics/partners/RDW-gold.svg";
import vanmossel from "../../Resources/Pics/partners/vanmossel-gold.svg";
import fokker from "../../Resources/Pics/partners/fokker-gold.svg";
import sony from "../../Resources/Pics/partners/sony-gold.svg";
import assen from "../../Resources/Pics/partners/assen-gold.svg";
import pouw from "../../Resources/Pics/partners/pouw-gold.svg";
import rabobank from "../../Resources/Pics/partners/rabobank-gold.svg";
import weather from "../../Resources/Pics/partners/weather-gold.svg";
// silver partners
import infinite from "../../Resources/Pics/partners/infinite-silver.svg";
import salomon from "../../Resources/Pics/partners/salomon-silver.svg";
import kvaser from "../../Resources/Pics/partners/kvaser-silver.svg";
import eekels from "../../Resources/Pics/partners/eekels-silver.svg";
import simba from "../../Resources/Pics/partners/simba-silver.svg";
import miontronics from "../../Resources/Pics/partners/miontronics-silver.svg";
import eurocircuits from "../../Resources/Pics/partners/eurocircuits-silver.svg";
import k3d from "../../Resources/Pics/partners/k3d-silver.svg";
import TPEE from "../../Resources/Pics/partners/TPEE-silver.svg";
import raytek from "../../Resources/Pics/partners/raytek-silver.svg";
import rood from "../../Resources/Pics/partners/rood-silver.svg";
import mabutex from "../../Resources/Pics/partners/mabutex-silver.svg";
// bronze partners
import boikon from "../../Resources/Pics/partners/boikon-bronze.svg";
import easy_composites from "../../Resources/Pics/partners/easy-composites-bronze.svg";
import defensity from "../../Resources/Pics/partners/defensity-bronze.svg";
import dutchbeans from "../../Resources/Pics/partners/dutchbeans-bronze.svg";
import trip from "../../Resources/Pics/partners/trip-bronze.svg";
import wurth from "../../Resources/Pics/partners/wurth-bronze-new.svg";
import altium from "../../Resources/Pics/partners/altium-bronze.svg";
import emkay from "../../Resources/Pics/partners/emkay-bronze.svg";
import prohelion from "../../Resources/Pics/partners/prohelion-bronze.svg";
import d3 from "../../Resources/Pics/partners/d3-bronze.svg";
import hexagon from "../../Resources/Pics/partners/hexagon-bronze.svg";
import meilink from "../../Resources/Pics/partners/meilink-bronze.svg";
import igus from "../../Resources/Pics/partners/igus-bronze.svg";
import elcee from "../../Resources/Pics/partners/elcee-bronze.svg";
import vdl from "../../Resources/Pics/partners/vdl-bronze.svg";
import roboflex from "../../Resources/Pics/partners/roboflex-bronze.svg";
// import hydroflex from "../../Resources/Pics/partners/hydroflex-bronze.svg";
// import adruu from "../../Resources/Pics/partners/adruu-bronze.svg";
import bridgestone from "../../Resources/Pics/partners/bridgestone-bronze.svgz";
import stuwkrach from "../../Resources/Pics/partners/stuwkrach-broze.svg";
import unive from "../../Resources/Pics/partners/unive-bronze.svg";
import fluctus from "../../Resources/Pics/partners/fluctus-bronze.svg";
import makerspace from "../../Resources/Pics/partners/makerspace-bronze.svg";
import tkp from "../../Resources/Pics/partners/tkp-bronze.svg";
import repos from "../../Resources/Pics/partners/repos-bronze.svg";
import bionic from "../../Resources/Pics/partners/bionic-bronze.svg";
import saba from "../../Resources/Pics/partners/saba-bronze.svg";
import pontifex from "../../Resources/Pics/partners/pontifex-bronze.svg";
import target from "../../Resources/Pics/partners/target-bronze.svg";
import canon from "../../Resources/Pics/partners/canon-bronze.svg";
// import hydro from "../../Resources/Pics/partners/hydro-bronze.svg";
import omnyacc from "../../Resources/Pics/partners/omnyacc-bronze.svg";
import stm from "../../Resources/Pics/partners/STM.svg";
import PartnersInfo from "./Partners-info";
import cablemasters from "../../Resources/Pics/partners/cablemasters-gold.svg";
import de_boer from "../../Resources/Pics/partners/de-boer-bouwmanagement-bronze-duotone1.svg"; 
import sportconfex from "../../Resources/Pics/partners/sportconfex.svg";


export default function JoinUs(){
    return(
        <div>
            <PartnerScroll/>
            <div className="container extra-space simple-section">
                <div className="row simple-section-header">
                    <h1>Why partner with us?</h1>
                </div>
                <div className="row simple-section-body">
                    <p>
                    Become a partner of Top Dutch Solar Racing and be part of a movement that is shaping the future of clean mobility and renewable energy. 
                    By joining forces with us, your brand will gain international exposure at major solar racing events, including our iconic 3000km race 
                    through the Australian outback. This is your chance to showcase your commitment to sustainability while reaching a global audience in 
                    the Netherlands, Australia, and beyond.
                    </p>

                    <p>
                    As a partner, you’ll align with cutting-edge technology and innovation, collaborate with ambitious students and industry leaders, and 
                    inspire future generations to take bold steps toward a greener future. With tailored partnership packages and access to a dynamic 
                    network of innovators, businesses, and professionals, you’ll have the opportunity to make a real impact on the future of renewable 
                    energy.
                    </p>
                    
                    <p>
                    Join us today and be part of something extraordinary! We are driven to get there, together!
                    </p>
                </div>
            </div>

            <div className="title-container extra-space">
                
                <div className="image green " style={{left: "24vw", top: "7vh"}}>
                    <img src={rug} alt="Icon 2" />
                </div>
                   
                    <h1 className="title text-move" onClick={() => window.location.href = "mailto:acquisition@solarracinggroningen.nl"} >
                        <text className="title" href="mailto:acquisition@solarracinggroningen.nl"> /join our network</text>
                    </h1>
                    
                <div className="image green" style={{left: "65vw", top: "5vh"}}>
                    <img src={hanze} alt="Icon 3" />
                </div>
                <div className="image green" style={{left: "27vw", top: "27vh"}}>
                    <img src={provGron} alt="Icon 4" />
                </div>
                <div className="image green" style={{left: "55vw", top: "30vh"}}>
                    <img src={vanGroningen} alt="Icon 4" />
                </div>

                <div className="image knowledge" style={{left: "15vw", top: "3vh"}}>
                    <img src={alfa} alt="Icon 1" />
                </div>
                <div className="image knowledge" style={{left: "42vw", top: "42vh", filter: "invert(0)"}}>	
                    <img src={firda} alt="Icon 1" />
                </div>
                <div className="image knowledge" style={{left: "86vw", top: "10vh"}}>
                    <img src={noord} alt="Icon 1" />
                </div>

                <div className="image plat" style={{left: "45vw", top: "6vh"}}>
                    <img src={oldernburg} alt="Icon 1" />
                </div>
                <div className="image plat" style={{left: "75vw", top: "24vh"}}>
                    <img src={seatrade} alt="Icon 1" />
                </div>
                <div className="image plat" style={{left: "13vw", top: "25vh"}}>
                    <img src={fokker} alt="Icon 1" />
                </div>
                <div className="image plat" style={{left: "70vw", top: "0"}}>
                    <img src={greatwaves} alt="Icon 1" />
                </div>

                <div className="image gold" style={{left: "85vw", top: "40vh"}}>
                    <img src={bionic} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "2vw", top: "25vh"}}>
                    <img src={RDW} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "90vw", top: "2vh"}}>
                    <img src={sony} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "35vw", top: "0vh"}}>
                    <img src={assen} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "20vw", top: "45vh"}}>
                    <img src={cablemasters} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "4vw", top: "1vh"}}>
                    <img src={pouw} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "70vw", top: "46vh"}}>
                    <img src={rabobank} alt="Icon 1" />
                </div>

            </div>
            
        </div>
    )
}